import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Banner, Grid, GridItem } from '../components'

const pageQuery = graphql`
  {
    images: allGraphCmsAsset {
      nodes {
        fileName
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              quality: 90
              formats: JPG
            )
          }
        }
      }
    }
  }
`

const Home = () => {
  const { images } = useStaticQuery(pageQuery)
  const banner = getImage(images.nodes[0].localFile)

  return (
    <>
      <Banner title='What do I put here?' image={banner} />
      <Grid>
        {images.nodes.map((image, i) => {
          if (i > 0) {
            return <GridItem key={i} image={image} />
          }
        })}
      </Grid>
    </>
  )
}

export default Home
